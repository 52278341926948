<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="严重程度" prop="warnType">
                <a-select placeholder="请选择告警严重程度" v-model="queryParam.warnDegree">
                  <a-select-option v-for="(d, index) in warnDegreeOptions" :key="index" :value="d.dictValue">{{
                    d.dictLabel
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="告警类型" prop="warnType">
                <a-select placeholder="请选择告警类型" v-model="queryParam.warnType">
                  <a-select-option v-for="(d, index) in warnTypeOptions" :key="index" :value="d.dictValue">{{
                    d.dictLabel
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="告警描述">
                  <a-input v-model="queryParam.warnDesc" placeholder="请输入告警描述" allow-clear />
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24">
                <a-form-item label="告警ID">
                  <a-input v-model="queryParam.typeId" placeholder="请输入告警ID" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="告警触发时间">
                  <a-range-picker
                    style="width: 100%"
                    showTime
                    v-model="daterangeTriggedTime"
                    valueFormat="YYYY-MM-DD HH:mm:ss"
                    format="YYYY-MM-DD HH:mm:ss"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="告警清除时间">
                  <a-range-picker
                    style="width: 100%"
                    showTime
                    v-model="daterangeClearTime"
                    valueFormat="YYYY-MM-DD HH:mm:ss"
                    format="YYYY-MM-DD HH:mm:ss"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="6" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:vehicleWarn:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenant:iot:vehicleWarn:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tenant:iot:vehicleWarn:remove']"
        >
          <a-icon type="delete" />删除
        </a-button> -->
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicleWarn:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :warnTypeOptions="warnTypeOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="warnType" slot-scope="text, record">
          {{ warnTypeFormat(record) }}
        </span>
        <a-span slot="triggedTime" slot-scope="text, record">
          {{ parseTime(record.triggedTime) }}
        </a-span>
        <span slot="clearTime" slot-scope="text, record">
          {{ parseTime(record.clearTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleWarn:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:vehicleWarn:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleWarn:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:vehicleWarn:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listVehicleWarn, delVehicleWarn, exportVehicleWarn } from '@/api/iot/vehicleWarn'
import CreateForm from './modules/CreateForm'

export default {
  name: 'VehicleWarn',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 告警类型(字典: iot_vehicle_fault_type)字典
      warnTypeOptions: [
        {
          dictValue: '',
          dictLabel: '全部'
        },
        {
          dictValue: '1',
          dictLabel: '实时告警'
        },
        {
          dictValue: '2',
          dictLabel: '历史告警'
        }
      ],
      // 告警严重程序
      warnDegreeOptions: [
        {
          dictValue: '',
          dictLabel: '全部'
        },
        {
          dictValue: '1',
          dictLabel: '普通告警'
        },
        {
          dictValue: '2',
          dictLabel: '严重告警'
        }
      ],
      warnLevelOptions: [],
      // 告警触发时间(车端)时间范围
      daterangeTriggedTime: [],
      // 告警清除时间(车端)时间范围
      daterangeClearTime: [],
      // 告警状态字段
      warnStatusOptins: [],
      // 查询参数
      queryParam: {
        vehicleId: null,
        uuid: null,
        triggedTime: null,
        clearTime: null,
        typeId: null,
        warnStatus: null,
        warnType: '1',
        warnDegree: '2',
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          align: 'center'
        },
        {
          title: '严重程度',
          dataIndex: 'level',
          align: 'center',
          customRender: (text, record, index) => {
            const iLevel = record.level * 1
            if (iLevel === 200 || iLevel === 300) {
              return '严重告警'
            } else return '普通告警'
          }
        },
        {
          title: '告警类型',
          dataIndex: 'warnType',
          scopedSlots: { customRender: 'warnType' },
          align: 'center'
        },
        {
          title: '告警级别',
          dataIndex: 'level',
          align: 'center',
          customRender: (text, record, index) => {
            return this.selectDictLabel(this.warnLevelOptions, record.level)
          }
        },
        {
          title: '告警描述',
          dataIndex: 'warnDesc',
          align: 'center'
        },
        {
          title: '告警触发时间',
          dataIndex: 'triggedTime',
          scopedSlots: { customRender: 'triggedTime' },
          align: 'center'
        },
        {
          title: '告警清除时间',
          dataIndex: 'clearTime',
          scopedSlots: { customRender: 'clearTime' },
          align: 'center'
        },
        {
          title: '告警ID',
          dataIndex: 'typeId',
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    if (this.$route.query && this.$route.query.vehicleId) {
      this.queryParam.vehicleId = this.$route.query.vehicleId
    }
    this.getList()
    // this.getDicts('iot_vehicle_fault_type').then(response => {
    //   this.warnTypeOptions = response.data
    // })
    this.getDicts('iot_vehicle_warnStatus').then((response) => {
      this.warnStatusOptins = response.data
    })
    this.getDicts('iot_vehicle_warning_level').then((response) => {
      this.warnLevelOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询告警记录列表 */
    getList() {
      console.log('vehicleWarn')
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeTriggedTime !== null &&
        this.daterangeTriggedTime !== '' &&
        this.daterangeTriggedTime.length !== 0
      ) {
        this.queryParam.params['beginTriggedTime'] = this.daterangeTriggedTime[0]
        this.queryParam.params['endTriggedTime'] = this.daterangeTriggedTime[1]
      }
      if (this.daterangeClearTime !== null && this.daterangeClearTime !== '' && this.daterangeClearTime.length !== 0) {
        this.queryParam.params['beginClearTime'] = this.daterangeClearTime[0]
        this.queryParam.params['endClearTime'] = this.daterangeClearTime[1]
      }
      listVehicleWarn(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 告警类型(字典: iot_vehicle_fault_type)字典翻译
    warnTypeFormat(row, column) {
      return this.selectDictLabel(this.warnTypeOptions, row.warnType)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeTriggedTime = []
      this.daterangeClearTime = []
      this.queryParam = {
        vehicleId: undefined,
        uuid: undefined,
        typeId: undefined,
        warnStatus: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleWarn(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleWarn(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
